import React from 'react';

interface ContainerProps {
  children: React.ReactNode;
}

const Container = (props: ContainerProps) => (
  <div className='m-auto lg:w-3/4'>
    {props.children}
  </div>
);

export default Container;
