import React from 'react';

interface JumbotronProps {
  backgroundColor: string;
  backgroundImage?: string;
  children: React.ReactNode;
};

const Jumbotron = (props: JumbotronProps) => (
  <div className='flex justify-center w-100'>
    <div className='m-2 p-10 h-1/3 bg-polkadot rounded-md'>
      {props.children}
    </div>
  </div>
);

export default Jumbotron;
