interface ImageButtonProps {
  imageSrc: any;
  label: string;
  linkHref: string;
}

const ImageButton = (props: ImageButtonProps) => (
  <div className='m-2 bg-primary rounded-md border-2 border-white transition ease-in-out duration-300 hover:bg-white '>
    <a href={props.linkHref} className='block p-2 h-100 text-center'>
      <img src={props.imageSrc} className='inline-block mr-1 mb-1 h-5' alt={`${props.label}-img`}/>
      <p className='inline-block text-xl text-center'>{props.label}</p>
    </a>
  </div>
);

export default ImageButton;
