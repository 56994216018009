interface ImageListingProps {
  imageSrc: string;
  label: string;
  price: number;
  linkHref?: string;
}

const ImageListing = (props: ImageListingProps) => (
  <div className='flex flex-col m-1 drop-shadow-lg'>
    <div className='overflow-hidden h-[250px] w-[250px] rounded-md'>
      <img className='relative top-[-25px] max-w-full' src={props.imageSrc} alt={`${props.label} listing`} />
    </div>
    <p className='overflow-hidden w-[250px] font-bold text-ellipsis whitespace-nowrap'>{props.label}</p>
    <p>${props.price.toFixed(2)}</p>
  </div>
);

export default ImageListing;
