import React from 'react';

interface TabListItemProps {
  label: string;
  isActive: boolean;
  tabId: string;
  onTabChangeHandler: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const TabListItem = (props: TabListItemProps) => {
  const activeClass = props.isActive ? 'bg-primary' : '';

  return (
    <div id={props.tabId} className={`${activeClass} p-2 text-lg text-center rounded-md hover:cursor-pointer tablist-tab`} onClick={(e) => props.onTabChangeHandler(e)}>
      <p>{props.label}</p>
    </div>
  );
};

export default TabListItem;
