import React from 'react';

import Jumbotron from './components/ui/Jumbotron';
import ImageButton from './components/ui/ImageButton';

import etsyLogo from './img/etsy.svg';
import instagramLogo from './img/instagram.svg';

import blanket1 from './img/listings/blankets/blanket1.png';
import hat1 from './img/listings/hats/hat1.png';

import ImageListing from './components/ui/ImageListing';
import PageDetails from './components/ui/PageDetails';
import TabList from './components/ui/TabList';
import TabListItem from './components/ui/TabListItem';
import Container from './components/ui/Container';

class App extends React.Component {

  onTabChange = (e: React.MouseEvent<HTMLDivElement>) => {
    const tabs = Array.from(document.getElementsByClassName('tablist-tab'));
    const pages = Array.from(document.getElementsByClassName('page-details'));

    tabs.forEach((tab) => {
      tab.classList.remove('bg-primary');
    });

    pages.forEach((page) => {
      page.classList.add('hidden');
    });

    e.currentTarget.classList.add('bg-primary');
    document.querySelector(`[data-tab-id='${e.currentTarget.id}']`)?.classList.remove('hidden');
  }

  render() {
    return (
      <Container>
        <Jumbotron backgroundColor='primary'>
          <h1 className='mt-5 mx-5 pt-3 px-3 text-7xl text-center font-logo leading-loose'>Bundle Up Baby Crochet</h1>
          <h2 className='m-5 p-3 text-2xl text-center'>High quality, handmade things made by weaving yarn together</h2>
          <div className='flex flex-col sm:flex-row justify-center'>
            <ImageButton
              imageSrc={etsyLogo}
              label='Shop on Etsy'
              linkHref='https://www.etsy.com/shop/bundleupbabycrochet'
            />
            <ImageButton
              imageSrc={instagramLogo}
              label='Follow on Instagram'
              linkHref='https://www.instagram.com/bundleupbabycrochet/'
            />
          </div>
        </Jumbotron>
        <TabList>
          <TabListItem tabId='tab-blankets' label='Blankets' isActive={true} onTabChangeHandler={this.onTabChange} />
          <TabListItem tabId='tab-accessories' label='Hats &amp; Scarves' isActive={false} onTabChangeHandler={this.onTabChange} />
          <TabListItem tabId='tab-about' label='About' isActive={false} onTabChangeHandler={this.onTabChange} />
        </TabList>
        <PageDetails tabName='tab-blankets' hidden={false}>
          <ImageListing imageSrc={blanket1} label='Crochet newborn boy blue, green, and white blanket and hat' price={42.00} />
        </PageDetails>
        <PageDetails tabName='tab-accessories' hidden={true}>
          <ImageListing imageSrc={hat1} label='Women/teen crochet wine, green and tan winter hat with faux fur Pom Pom' price={12.00} />
        </PageDetails>
        <div data-tab-id='tab-about' className='hidden page-details'>About</div>
      </Container>
    );
  }
}

export default App;
