import React from 'react';

interface TabListProps {
  children: React.ReactNode;
}

const TabList = (props: TabListProps) => (
  <div className='flex flex-row justify-around'>
    {props.children}
  </div>
);

export default TabList;
