import React from 'react';

interface PageDetailsProps {
  tabName: string;
  hidden: boolean;
  children: React.ReactNode;
}

const PageDetails = (props: PageDetailsProps) => {
  const isHidden = props.hidden ? 'hidden' : '';
  return (
    <div data-tab-id={props.tabName} className={`flex flex-row justify-center md:justify-between flex-wrap page-details ${isHidden}`}>
      {props.children}
    </div>
  );
}

export default PageDetails;
